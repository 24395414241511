import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isSafari, browserVersion } from 'react-device-detect';
import { Person } from '../../utils/graphql';
import SeeMoreButton from '../buttons/SeeMoreButton';
import TeamMember from './team-member';

type TeamSectionProps = {
  title: string;
  titleButton: string;
  content: Person[];
};

const TeamSection: React.VoidFunctionComponent<TeamSectionProps> = ({
  title,
  titleButton,
  content,
}) => {
  const INIT_ITEMS = 4;
  const [nbDisplayedItems, setDisplayedItems] = React.useState(INIT_ITEMS);
  const { t } = useTranslation('team');
  const excludeSafari16 = !(isSafari && browserVersion == '16');

  return (
    <section>
      <h2 className="mt-16 text-4xl font-bold">{title}</h2>
      <div className="flex flex-wrap justify-around pt-3">
        {content.map((person, index) => (
          <div
            className={excludeSafari16 && index >= nbDisplayedItems ? 'hidden' : ''}
            key={person.id}
          >
            <TeamMember key={person.id} person={person} />
          </div>
        ))}
      </div>
      {excludeSafari16 && nbDisplayedItems < content.length ? (
        <SeeMoreButton
          handleClick={() => setDisplayedItems(content.length)}
          text={t('button.see-all', { section: titleButton })}
        />
      ) : undefined}
    </section>
  );
};

export default TeamSection;
