import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { Theme } from './Colors';

type HiringProps = {
  className: string;
  theme: Theme;
};

const Hiring: React.VoidFunctionComponent<HiringProps> = ({ className, theme }) => {
  const { t } = useTranslation('hiring');

  const joinUs = t('join-us');

  return (
    <div className={`flex flex-col justify-center lg:flex-row ${className} ${theme.class}`}>
      <div className="flex flex-1 flex-col justify-center">
        <div className="px-4 py-8 font-medium sm:px-8 lg:py-0">
          <h3 className="mt-0 text-left text-3xl font-bold leading-snug sm:text-4xl">
            {t('title')}
          </h3>
          <p>{t('text')}</p>
          <LocalizedLink
            to="/jobs/"
            className={`mt-6 inline-block whitespace-nowrap border border-solid border-current px-4 py-3 leading-tight sm:px-6 ${theme.pseudoClass}`}
            title={joinUs}
          >
            {joinUs}
          </LocalizedLink>
        </div>
      </div>
      <div className="hidden flex-1 lg:block">
        <StaticImage
          src="../images/join-us-conviviality.jpg"
          className="h-full w-full"
          title={joinUs}
          alt={joinUs}
          placeholder="blurred"
        />
      </div>
    </div>
  );
};

export default Hiring;
