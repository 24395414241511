import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Footer from '../components/landing/Footer';
import DefaultLayout from '../components/layouts/default-layout';
import TeamSection from '../components/team/teamSection';
import * as Colors from '../components/Colors';
import { AllImage, PageContextProps, Person } from '../utils/graphql';
import useElementOnScreen from '../utils/useElementOnScreen';

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "team" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 245, placeholder: BLURRED)
        }
        name
      }
    }
  }
`;

type TeamQueryProps = {
  allFile: AllImage;
};

type TeamProps = PageProps<TeamQueryProps, PageContextProps>;

const Team: React.VoidFunctionComponent<TeamProps> = ({
  data: {
    allFile: { nodes: teamMemberImages },
  },
  pageContext,
}) => {
  const { t } = useTranslation('team');
  const pageTitle = t('head-title');

  const GetPeople = function GetPeople(tKey: string) {
    return React.useMemo(() => {
      const result: Person[] = [];
      const team = t<string, { [key: string]: Person }>(tKey, {
        returnObjects: true,
        defaultValue: {},
      });
      Object.keys(team).forEach((key) => {
        const person = team[key];
        person.id = key;
        person.image = teamMemberImages.find((img) => img.name === key);
        result.push(person);
      });
      return result;
    }, [tKey]);
  };
  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <DefaultLayout
      headTitle={pageTitle}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      showContactForm={false}
    >
      <div className="container my-5 px-4 py-5 text-center text-black" id="team">
        <div
          ref={animatedElement}
          className={`translate-y-12 opacity-0 ${isInView ? 'animate-slidein' : ''}`}
        >
          <p className="text-2xl font-medium">{t('subtitle')}</p>
          <h1 className="mb-20 mt-4">{t('title')}</h1>
        </div>
        <TeamSection
          title={t('team-title')}
          titleButton={t('team-title-button')}
          content={GetPeople('team')}
        />
        <TeamSection
          title={t('growthTeam-title')}
          titleButton={t('growthTeam-title-button')}
          content={GetPeople('growthTeam')}
        />
        <TeamSection
          title={t('partners-title')}
          titleButton={t('partners-title-button')}
          content={GetPeople('partners')}
        />
      </div>
      <Footer
        pageTitle={pageTitle}
        hiringClassName={Colors.WHITE_ON_BLACK}
        logoClassName={Colors.BLACK_ON_WHITE}
        theme={{
          class: Colors.WHITE_ON_BLACK,
          pseudoClass: Colors.WHITE_ON_BLACK_PSEUDO_CLASS,
        }}
      />
    </DefaultLayout>
  );
};

export default Team;
