import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BLUE_ON_WHITE, BLUE_ON_WHITE_PSEUDO_CLASS } from '../Colors';

type SeeMoreButtonProps = {
  text?: string;
  buttonClass?: string;
  pseudoClass?: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
};

const SeeMoreButton: React.VoidFunctionComponent<SeeMoreButtonProps> = ({
  text,
  buttonClass = BLUE_ON_WHITE,
  pseudoClass = BLUE_ON_WHITE_PSEUDO_CLASS,
  handleClick,
}) => {
  const { t } = useTranslation('components');
  text = text ? text : t('button.see-more');

  return (
    <button
      className={`mx-auto mb-3 inline-block whitespace-nowrap rounded-1 border-1 border-solid px-4 py-3 font-semibold ${buttonClass} ${pseudoClass}`}
      title={text}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default SeeMoreButton;
