import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Person } from '../../utils/graphql';

type TeamMemberProps = {
  person: Person;
};

const TeamMember: React.VoidFunctionComponent<TeamMemberProps> = ({ person }) => (
  <div id={person.id} className="mx-0 w-56 py-6 text-center text-black">
    <div className="mx-auto w-full">
      <div
        tabIndex={0}
        role="button"
        className={`relative h-[245px] w-[245px] perspective-screen ${
          person.image
            ? 'active:rotate-child-y-180 focus:rotate-child-y-180 hover:rotate-child-y-180'
            : ''
        }`}
      >
        <div className="transform-style-3d relative h-[245px] w-[245px] origin-center transition-transform duration-500">
          {person.image ? (
            <div className="backface-hidden absolute left-0 top-0 h-full w-full">
              <GatsbyImage
                image={person.image.childImageSharp.gatsbyImageData}
                title={person.fullName}
                alt={person.fullName}
              />
            </div>
          ) : null}
          <div
            className={`backface-hidden absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black p-4 text-xl font-medium text-white ${
              person.image ? 'rotate-y-180' : ''
            }`}
          >
            <p className="line-clamp-[8] overflow-hidden text-ellipsis">{person.job}</p>
          </div>
        </div>
      </div>
      <h3 className="mb-0 mt-4 text-base font-bold leading-6">{person.fullName}</h3>
    </div>
  </div>
);

export default TeamMember;
